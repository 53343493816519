@media all and (max-width: 992px) {
    body > .container-fluid > .row {
        min-height: 100% !important;
        height: fit-content !important;
    }

    /* Sidebar */
    .sidebar {
        max-height: 90px !important;
        width: 100% !important;
        overflow: hidden;
        position: fixed;
        bottom: 0;
        z-index: 9999999;

        .sidebar-text-elm {
            display: none !important;
        }

        .item-wrapper {
            display: inline-block !important;
            width: 50px !important;
            max-width: 50px !important;
            margin: 0px !important;
        }
    }

}
@media all and (max-width: 768px) {
    .fa-3x {
        font-size: 2rem !important;
    }
    .fa-2x {
        font-size: 1rem !important;
    }
}

@media all and (max-width: 576px) {
    .fa-3x {
        font-size: 1.5rem !important;
    }
    .fa-2x {
        font-size: 0.875rem !important;
    }
}

@media all and (min-width: 1201px) {
    .wrapper-top .hp-circle {
        width: 21% !important;   
    }
}
@media all and (max-width: 992px) {
    .hp-wrapper-circle .wrapper-container {
        margin-top: 10%;
    }
}

@media all and (max-width: 767px) {
    .hp-wrapper-circle .wrapper-top {
        margin-top: -6%;
    }
}
@media all and (min-width: 768px) and (max-width: 992px) {
    .wrapper-top .hp-circle {
        width: 30% !important;   
    }
}
@media all and (min-width: 488px) and (max-width: 767px) {
    .wrapper-top .hp-circle {
        width: 11% !important;   
    }
    .hp-wrapper-circle .wrapper-container {
        margin-top: 5%;
    }
}
@media all and (max-width: 487px) {
    .wrapper-top .hp-circle {
        width: 65px !important;   
    }
}
/** Fontsize on different viewport */
@media all and (min-width: 768px) and (max-width: 992px) {
    .h1 {
        // font-size: 2rem !important;
        font-size: 30px !important;
    }
    .h2 {
        font-size: 25px !important;
        // font-size: 1.75rem !important;
    }
    .h3 {
        font-size: 1.45rem !important;
    }
    .h4 {
        font-size: 1.25rem !important;
    }
    .h5 {
        font-size: 1.15rem !important;
    }
}
@media all and (min-width: 488px) and (max-width: 767px) {
    .h1 {
        font-size: 25px !important;
    }
    .h2 {
        font-size: 22px !important;
    }
    .h3 {
        font-size: 19px !important;
    }
    .h4 {
        font-size: 16px !important;
    }
    .h5 {
        font-size: 13px !important;
    }
}
@media all and (max-width: 487px) {
    .h1 {
        font-size: 22px !important;
    }
    .h2 {
        font-size: 19px !important;
    }
    .h3 {
        font-size: 16px !important;
    }
    .h4 {
        font-size: 13px !important;
    }
    .h5 {
        font-size: 11px !important;
    }
}