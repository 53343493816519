/* cyrillic-ext */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/oswald/v35/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvsUtiZTaR.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/oswald/v35/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvsUJiZTaR.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/oswald/v35/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvsUliZTaR.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/oswald/v35/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvsUhiZTaR.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
  /* latin */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/oswald/v35/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvsUZiZQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}
h1, h2, h3, h4, h5 {
    font-family: 'Oswald', sans-serif !important;
}

/** Start short tag */
.dashboard-shadow {
    box-shadow: 4px 4px 10px 0px rgb(99, 99, 99) !important;
}
// Text
.text-uppercase {
    text-transform: uppercase;
}
// Btn
.btn-radius {
    border-radius: 50px !important;
}
// Margin
.mt-9 {
    margin-top: 5rem !important;
}
// Font size
@for $i from 1 through 50 {
    .font-size-#{$i} {
        font-size: 1px*$i !important;
    }
    .mnh-#{$i} {
        min-height: 10px*$i !important;
    }
}
@for $i from 1 through 100 {
    .top-#{$i} {
        top: round(percentage($i/100)) !important;
    }
}
@for $i from 1 through 10 {
    .border-#{$i} {
        border-bottom-width: 1px*$i !important;
        border-left-width: 1px*$i !important;
        border-right-width: 1px*$i !important;
        border-top-width: 1px*$i !important;
    }
}

@for $i from 1 through 1000 {
    .zindex-#{$i} {
        z-index: 1*$i !important;
    }
}
.dev-badge {
    background-color: rgb(97,97,97);
    color: white;
    font-size: 11px !important;
    text-transform: uppercase;
}

.arrow-up {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid black;
}
.arrow-down {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    
    border-top: 10px solid black;
}
.dashboard-sub-navbar .nav-item .position-absolute {
    bottom: -2px;
}
.dashboard-sub-navbar .nav-item {
    color: color('menu');
}

.nav-tabs {
    padding: 0 5px 0 5px !important;
}
// Border
.border-radius-bottom-0 {
    border-radius: 0.25rem 0.25rem 0 0 !important;
}
.border-radius-top-0 {
    border-radius: 0 0 0.25rem 0.25rem !important;
}
.border-radius-left-0 {
    border-radius: 0 0.25rem 0.25rem 0rem !important;
}
.border-radius-right-0 {
    border-radius: 0.25rem 0rem 0rem 0.25rem !important;
}
.border-radius-0 {
    border-radius: 0 0 0 0 !important;
}
.border-1 {
    border-top-width: 0.5rem;
}
.border-2 {
    border-top-width: 1rem;
}
.border-3 {
    border-top-width: 1.5rem;
}
.border-solid {
    border-top-style: solid;
}
.border-target {
    border: solid dodgerblue 1px;
    box-shadow: 0px 0px 5px black;
    border-radius: 9px;
}
.hide {
    display: none;
}
.h-min-content {
    height: min-content !important;
}
.min-h-100-px {
    min-height: 100px !important;
}
.spinner-border-xs {
    width: 25px !important;
    height: 25px !important;
}
.btn:empty {
    padding: 0 !important;
}
.text-bold {
    font-weight: bold;
}
.caption-side-top {
    caption-side: top;
}
/** End short tag */

/** Start img tag **/
.logo {
    max-height: 68px !important;
}
/** End img tag **/

.hp-wrapper-circle .wrapper-top {
    position: absolute;
    margin-top: -10%;
    width: 100%;
    left: 0;
    text-align: center;
}
.hp-wrapper-circle .wrapper-container {
    margin-top: 5%;
}
.input-group-text.mh-sm {
    min-width: 85px !important;
}
.input-group-text.mh-md {
    min-width: 130px !important;
}
.input-group-text.mh-lg {
    min-width: 180px !important;
}
.input-group-text.mh-xl {
    min-width: 220px !important;
}
.dataTables_filter input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.dataTables_filter input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}


table.dataTable thead > tr > th {
    padding-left: 30px !important;
    padding-right: initial !important;
}

.dataTable > thead > tr > th[class*="sort"]:before,
.dataTable > thead > tr > th[class*="sort"]:after {
    content: "" !important;
}
table.dataTable thead .sorting_asc {
    background: url("../img/sort_asc.png") no-repeat center left ;
}
table.dataTable thead .sorting_desc {
    background: url("../img/sort_desc.png") no-repeat center left;
}
table.dataTable thead .sorting {
    background: url("../img/sort_both.png") no-repeat center left;
}

@import "~bootstrap/scss/bootstrap";
@import "./media-queries.scss";

.bell-anime {
    animation-name: bell;
    animation-duration: 5s;
    color: white;
}
@keyframes bell {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(45deg);
    }
    20% {
        transform: rotate(-45deg);
    }
    30% {
        transform: rotate(0deg);
    }
    40% {
        transform: rotate(45deg);
    }
    50% {
        transform: rotate(-45deg);
    }
    60% {
        transform: rotate(0deg);
    }
    70% {
        transform: rotate(45deg);
    }
    80% {
        transform: rotate(-45deg);
    }
    90% {
        transform: rotate(0deg);
    }
}


$theme-colors: (
    'red': #BE0A26,
    'gray': #91A8B3,
    'lightgray': #C3CED4,
    'darkgray': #3E3D40,
    'yellow': #F3A700,
    'page': #E2E4E5,
    'menu': #212B34,
    'peach': #FF6042,
    'light-peach': rgba(255, 96, 66, 0.75),
    'aqua-green': #22AF7C,
    'd-blue': #30A7EC,
    'shadow': #1C303A
);

@function color($key: "blue") {
    @return map-get($colors, $key);
}
  
@function theme-color($key: "primary") {
    @return map-get($theme-colors, $key);
}
  
@function gray($key: "100") {
    @return map-get($grays, $key);
}

@function theme-color-level($color-name: "primary", $level: 0) {
    $color: theme-color($color-name);
    $color-base: if($level > 0, #000, #fff);
    $level: abs($level);
  
    @return mix($color-base, $color, $level * $theme-color-interval);
}

@each $color, $value in $theme-colors {
    /** Table **/
    .table-#{$color},
    .table-#{$color} > th,
    .table-#{$color} > td {
        background-color: $value;
    }
    .table-#{$color} th,
    .table-#{$color} td,
    .table-#{$color} thead th,
    .table-#{$color} tbody + tbody {
        border-color: theme-color-level(#{$color},-2);
    }

    .table-hover .table-#{$color}:hover {
        background-color: color-yiq($value);
    }

    .table-hover .table-#{$color}:hover > td,
    .table-hover .table-#{$color}:hover > th {
        background-color: color-yiq($value);
    }

    .carousel-indicators li.bg-#{$color} {
        background-color: $value;
        color: color-yiq($value) !important;
    }
    .carousel-indicators li.bg-#{$color}.active {
        background-color: theme-color('red');
    }

    footer ul li.text-#{$color} {
        color: $value;
    }

    .nav-tabs .nav-link.active {
        background-color: theme-color('menu');
        border-color: theme-color('menu');
        color: color-yiq(theme-color('menu'));
    }

    .breadcrumb .breadcrumb-item.active {
        color: theme-color('yellow');
    }

    .form-error-message {
        color: theme-color('red'); 
    }

    /** Btn **/
    .btn-#{$color} {
        color: color-yiq($value);
        background-color: $value;
        border-color: $value;
    }

    .btn-#{$color}:hover {
        color: color-yiq(theme-color-level(#{$color}, -2));
        background-color: theme-color-level(#{$color}, -2);
        border-color: theme-color-level(#{$color}, -2);
    }

    .btn-#{$color}:focus, .btn-#{$color}.focus {
        color: color-yiq(theme-color-level(#{$color}, -2));
        background-color: theme-color-level(#{$color}, -2);
        border-color: theme-color-level(#{$color}, -2);
        // box-shadow: 0 0 0 0.2rem theme-color-level(#{$color}, -3);
        box-shadow: none !important;

    }
    .btn-outline-#{$color}:not(:disabled):not(.disabled).active:focus, .btn-outline-#{$color}:not(:disabled):not(.disabled):active:focus, 
    .show>.btn-outline-#{$color}.dropdown-toggle:focus {
        box-shadow: none !important;
    }
    .btn-#{$color}.disabled, .btn-#{$color}:disabled {
        color: color-yiq(theme-color-level(#{$color}, -2));
        background-color: theme-color-level(#{$color}, -2);
        border-color: theme-color-level(#{$color}, -2);
    }

    .btn-#{$color}:not(:disabled):not(.disabled):active, .btn-#{$color}:not(:disabled):not(.disabled).active,
    .show > .btn-#{$color}.dropdown-toggle {
        color: color-yiq(theme-color-level(#{$color}, -3));
        background-color: theme-color-level(#{$color}, -3);
        border-color: theme-color-level(#{$color}, -3);
    }

    .btn-#{$color}:not(:disabled):not(.disabled):active:focus, .btn-#{$color}:not(:disabled):not(.disabled).active:focus,
    .show > .btn-#{$color}.dropdown-toggle:focus {
        box-shadow: none !important;
        // box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    }
    .btn-outline-#{$color} {
        color: $value;
        border-color: $value;
    }

    .btn-outline-#{$color}:hover {
        color: color-yiq($value);
        background-color: $value;
        border-color: $value;
    }

    .btn-outline-#{$color}:focus, .btn-outline-#{$color}.focus {
        box-shadow: none !important;
        // box-shadow: 0 0 0 0.2rem theme-color-level(#{$color}, -2);
    }

    .btn-outline-#{$color}.disabled, .btn-outline-#{$color}:disabled {
        color: $value;
        background-color: transparent;
    }

    .btn-outline-#{$color}:not(:disabled):not(.disabled):active, .btn-outline-#{$color}:not(:disabled):not(.disabled).active,
    .show > .btn-outline-#{$color}.dropdown-toggle {
        color: color-yiq($value);
        background-color: $value;
        border-color: $value;
    }

    .btn-outline-#{$color}:not(:disabled):not(.disabled):active:focus, .btn-outline-#{$color}:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-#{$color}.dropdown-toggle:focus {
        box-shadow: none !important;
    }

    /** Badge **/

    .badge-#{$color} {
        color: color-yiq($value);
        background-color: $value;
        border-color: $value;
    }

    a.badge-#{$color}:hover, a.badge-#{$color}:focus {
        color: color-yiq($value);
        background-color: $value;
        border-color: $value;
    }

    a.badge-#{$color}:focus, a.badge-#{$color}.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem theme-color-level(#{$color}, -3);
    }

    /** Alert **/
    .alert-#{$color} {
        color: color-yiq($value);
        background-color: $value;
        border-color: $value;
    }

    .alert-#{$color} hr {
        border-top-color: color-yiq($value);
    }

    .alert-#{$color} .alert-link {
        color: theme-color-level(#{$color}, -4);
    }

    /** BG **/
    .bg-#{$color} {
        background-color: $value;
        color: color-yiq($value);
    }

    a.bg-#{$color}:hover, a.bg-#{$color}:focus,
    button.bg-#{$color}:hover,
    button.bg-#{$color}:focus {
        background-color: $value;
    }

    /** TEXT **/
    .text-#{$color} {
        color: $value;
    }
    a.text-#{$color}:hover, a.text-#{$color}:focus {
        color: color-yiq($value);
    }

    /** HOVER **/
    .hover-#{$color} {
        transition: all .3s;
    }
    .hover-#{$color}:hover {
        background-color: $value;
        color: color-yiq($value);
        cursor: pointer;
    }
    .hover-#{$color}.rounded-xs {
        border-radius: 4px;
    }
    .hover-#{$color}:hover .text-#{$color} {
        color: color-yiq($value);
    }
    .hover-#{$color}:hover .text-muted {
        color: color-yiq($value) !important;
    }
    .hover-#{$color}:hover .border-#{$color} {
        border: 1px solid color-yiq($value) !important;

    }

    /** BORDER **/
    .border-#{$color} {
        border-color: $value !important;
    }

    .border-top-#{$color} {
        border-color: $value;
    }
    .border-right-#{$color} {
        border-color: $value;
    }
    .border-left-#{$color} {
        border-color: $value;
    }
    .border-bottom-#{$color} {
        border-color: $value;
    }

    .form-transparent.btn-#{$color} {
        background-color: $value;
        color: color-yiq($value);
    }
    input.btn-#{$color}::placeholder {
        color: white !important;
        opacity: 1;
    }
    .form-transparent.btn-#{$color}:hover {
        background-color: theme-color-level(#{$color},-4);
        color: color-yiq(theme-color-level(#{$color}, -4));
    }
    .text-hover-#{$color}:hover {
        transition: all .3s;
        cursor: pointer;
        color: theme-color(#{$color}) !important;
    }
    /** SIDEBAR **/
    .sidebar .item-wrapper.bg-hover-#{$color}:hover {
        background-color: theme-color(#{$color}); 
    }
    .sidebar .item-wrapper:hover .item-main.text-hover-#{$color} {
        color: theme-color(#{$color}) !important;
    }
    .sidebar .item-sub.text-hover-#{$color}:hover {
        color: theme-color(#{$color}) !important;
    }
    .sidebar .item-wrapper.bg-hover-#{$color}.active {
        background-color: theme-color(#{$color}); 
    }
    .sidebar .item-wrapper.active .item-main.text-hover-#{$color} {
        color: theme-color(#{$color}) !important;
    }
    .wrapper-top .hp-circle {
        width: 23%;
        margin: auto;
        background-color: theme-color('red');
        padding: 14px 15px;
        color: white;
        border-radius: 50%;
    }
    // .toggle-container.active.bg-#{$color} {
    //     background-color: theme-color-level(#{$color}, -12);
    //     color: color-yiq(theme-color-level(#{$color}, -12));
    // }
}

.select2-container--bootstrap4 .select2-results__group {
    padding: 6px;
    display: list-item;
    color: white;
    background-color: theme-color('menu');
}

.input-group.rounded-0 .select2-selection {
    border-radius: 0;
}

@import "./form.scss";

.circle {
    position: relative;
    display: block;
    margin: 2em 0;
    text-align: center;
}

.circle:after {
    display: block;
    padding-bottom: 100%;
    width: 100%;
    height: 0;
    border-radius: 50%;
    background-color: theme-color('yellow');
    content: "";
}

.circle:hover:after {
    background-color: theme-color-level('yellow', 3);
}

.circle__inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.circle__wrapper {
    display: table;
    width: 100%;
    height: 100%;
}

.circle__content {
    display: table-cell;
    padding: 1em;
    vertical-align: middle;
}
.form-control.hide {
    display: none;
}
.chart-container {
    position: relative;
    height: 100%;
    width: 100%;
}
.dashboard-graph {
    transition: all .3s linear;
}
.ui-resizable-handle.ui-resizable-se {
    // height: 10px;
    // width: 90px;
    // border: solid;
}
.graph-container {
    // min-height: 200px !important;
}
.resizable, .resizables {
    min-height: 150px;
    min-width: 150px;
}
.report-container {
    border-top: 2px solid #212529;
    border-bottom: 2px solid white;
    border-left: 2px solid white;
    border-right: 2px solid white;
}
#report-pdf .pdf-page-wrapper {
    // height: 100%;
    width: 100%;
    border: dotted 1px grey;
}
.resizable.is-active {
    // width: 120px;
    border-radius: 0.75rem;
    // padding: 20px;
    // margin: 1rem;
    border: solid 1px #29e;
    color: white;
    // font-size: 20px;
    font-family: sans-serif;
    overflow: hidden;
  
    touch-action: none;
  
    /* This makes things *much* easier */
    _box-sizing: border-box;
}
.leaflet-top.leaflet-left {
    display: flex;
    flex-direction: column-reverse;
}
.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    width: fit-content;
}
.mh-700px {
    max-height: 700px !important;
    overflow: auto;
}
.mh-550px {
    max-height: 550px !important;
    overflow: auto;
}
.mh-500px {
    max-height: 500px !important;
    overflow: auto;
}
.mh-300px {
    max-height: 300px !important;
    overflow: auto;
}
.mh-200px {
    max-height: 200px !important;
    overflow: auto;
}
.mh-150px {
    max-height: 150px !important;
    overflow: auto;
}
.mh-100px {
    max-height: 100px !important;
    overflow: auto;
}
.js-debug {
    font-size: 12px !important;
    word-break: break-all;
}

.mnw-25px {
    min-width: 25px !important;
}
.mnw-50px {
    min-width: 50px !important;
}
.mnw-75px {
    min-width: 75px !important;
}
.mnw-100px {
    min-width: 100px !important;
}
.mnw-200px {
    min-width: 200px !important;
}
.mnw-300px {
    min-width: 300px !important;
}
.w-5 {
    width: 5%;
}
.w-10 {
    width: 10%;
}
.w-15 {
    width: 15%;
}
.w-20 {
    width: 20%;
}
.w-25 {
    width: 25%;
}
.w-40 {
    width: 40%;
}
.w-60 {
    width: 60%;
}
.w-80 {
    width: 80%;
}

.btn-url, .btn-load-modal, .btn-file-preview {
    transition: all .3s;
}
.btn-url:hover, .btn-load-modal:hover, .btn-file-preview:hover {
    cursor: pointer;
}

body #flashs-container {
    position: fixed;
    bottom: 5%;
    right: 2%;
    max-width: 300px;
    max-height: 300px;
    overflow: auto;
}

#flashs-container .toast {
    z-index: 99999;
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    min-height: auto!important;
}
.fc-daygrid-event-harness {
    display:inline-block;
    width: 94%;
}
.event-day {
    display:inline-block;
}
.event-day.realised {
    background-color: theme-color('d-blue');
    border-color: theme-color('d-blue');
    color: color-yiq(theme-color('d-blue'));
}
.event-day.realAndPlanified {
    background: linear-gradient(90deg, theme-color('d-blue') 50%, theme-color('peach') 50%) !important;
    color: white !important;
}
.event-day.planified {
    background-color: theme-color('peach');
    border-color: theme-color('peach');
    color: color-yiq(theme-color('peach'));
}
.btn-success {
    border-color: theme-color('aqua-green') !important;
    background-color: theme-color('aqua-green') !important;
    color: color-yiq(theme-color('aqua-green')) !important;
}
.btn-danger {
    border-color: theme-color('peach') !important;
    background-color: theme-color('peach') !important;
    color: color-yiq(theme-color('peach')) !important;
}

.breadcrumb {
    margin-bottom: 0.5rem !important;
    padding: 5px 10px !important;
}
.modal {
    height: auto !important;
    overflow: initial !important;
    position: relative !important;
    max-width: 100% !important;
}
.txt-shadow {
    text-shadow: 1px 2px 3px rgba(49, 49, 49, 0.815);
}
.box-shadow {
    box-shadow: 1px 2px 3px rgba(49, 49, 49, 0.815);
}
.circle-shadow {
    box-shadow: 0px 0px 2px rgba(49, 49, 49, 100%);
}
.txt-shadow-sm {
    text-shadow: 1px 1px 1px rgba(49, 49, 49, 0.815);
}
.dataTables_wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.dataTables_wrapper .row {
    width: 100%;
    margin-left: 0 !important;
}
.modal.container {
    padding: 0px 15px 15px 15px !important;
    z-index: 99999;
}
progress {
    width: 100%;
    height: 10px;
    -webkit-appearance: none;
    appearance: none;
}
progress::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 2px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}
progress::-webkit-progress-value {
    background-image:
        -webkit-linear-gradient(-45deg, 
                                transparent 33%, rgba(0, 0, 0, .1) 33%, 
                                rgba(0,0, 0, .1) 66%, transparent 66%),
        -webkit-linear-gradient(top, 
                                rgba(255, 255, 255, .25), 
                                rgba(0, 0, 0, .25)),
        -webkit-linear-gradient(left, theme-color('d-blue'), theme-color('peach')
    );

    border-radius: 2px; 
    background-size: 35px 20px, 100% 100%, 100% 100%;
}
.switch-big-loading {
    z-index: 999999;
    position: absolute;
    top: 3%;
    right: 5%;
}
.table td:not(.no-padding) {
    padding: 0.5rem !important;
}
.modal.container {
    width: 75%;
}
.modal.container.lg {
    max-width: 100% !important;
}
.modal.container.md {
    max-width: 70% !important;
}
.mce-content-body p {
    margin-bottom: 0.3rem !important;
}
.modal.container.w-80 {
    max-width: 80% !important;
}
.modal.container.w-50 {
    max-width: 50% !important;
}
.modal.top {
    vertical-align: top !important;
    margin-top: 7%;
}
.modal.container.file-preview {
    max-width: 80% !important;
    height: auto !important;

    .row {
        height: auto;
    }

    .modal-iframe {
        height: auto;
    }
}
.nav-link.toggle-container.btn {
    display: inline-block;
}
.select2.select2-container {
    width: 100% !important;
    min-width: 70%;
}
.input-group {
    .select2.select2-container {
        width: auto !important;
        min-width: 70%;
    }
}
.align-content-flex-start {
    align-content: flex-start;
}
.modal .modal-iframe {
    width: 100% !important;
    min-height: 750px !important;
}

.breadcrumb {
    background: none;
}
.flags-wrapper {
    position: absolute;
    right: 5%;
    z-index: 999;
    background: rgb(122, 122, 122);
    padding: 5px 10px;
    border-radius: 10px;
}
.btn.hide {
    display: none;
}
#debug-wrapper {
    position: absolute;
    z-index: 999999;
    max-width: 20%;
    min-width: 20%;
    background: rgb(255, 255, 255);
    top: 1%;
    left: 17%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
#debug-wrapper .row {
    max-height: 300px !important;
    overflow: auto;
}
#debug-wrapper:hover {
    cursor: grab;
}
#debug-wrapper .alert {
    font-size: 12px;
    word-wrap: break-word;
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    min-height: 1rem !important;
}
.fullcalendar-dashboard {
    .fc-header-toolbar {
        margin-bottom: 0px;

        .fc-toolbar-title {
            font-size: 23px;
            font-variant: all-petite-caps;
            color: #6c757d !important;
        }
        .fc-button {
            padding: 3px;
            font-size: 10px;
        }
    }
    
    .fc-daygrid-event {
        text-align: center;
        width: 100%;
    }
    .fc-daygrid-event .fc-daygrid-event-dot, .fc-daygrid-event .fc-event-time {
        display: none;
    }

    .fc-scrollgrid-section-header .fc-day {
        font-size: 13px;
    }

    .fc-daygrid-day-number {
        font-size: 11px;
        padding: 1px;
    }
}

.toggle-lg .toggle {
    min-width: 180px !important;
    font-size: 12px;
}
.toggle-container {
    cursor: pointer;
}

::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 5px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}
.select2-container .select2-search--inline {
    width: inherit;
}
.select2-dropdown {
    z-index: 99999 !important;
}
div.col-12.bg-white.py-2:empty {
    background-color: transparent;
}
.btn.toggle-handle:empty {
    padding: 0.2rem 0.75rem !important;
}
.toggle.ios, .toggle-on.ios, .toggle-off.ios { 
    border-radius: 20px; 
}
.form-check {
    width: max-content;
    position: inherit !important;
    display: inline-block !important;
    padding-left: 0 !important;
}
.jquery-modal.blocker.current {
    z-index: 2000;
}
.tinymce-col {
    border: groove 1px rgba(63,63,63,0.1);
}
.toggle-group {
    .btn {
        min-height: 30px;
        max-height: 30px;
        padding: 0.2rem 0.75rem !important;
    }
    .btn.btn-db-width {
        min-width: 70px;
    }
    .btn-danger {
        background-color: theme-color('peach') !important;
        border-color: theme-color('peach') !important;
        color: color-yiq(theme-color('peach')) !important;
    }
    .btn-success {
        background-color: theme-color('aqua-green') !important;
        border-color: theme-color('aqua-green') !important;
        color: color-yiq(theme-color('aqua-green')) !important;
    }
}
.toggle-100w {
    .form-check, .form-check .toggle {
        width: 100% !important;
    }
}
.tox-tinymce-aux {
    z-index: 9999999 !important;
}
.toggle.ios .toggle-handle { 
    border-radius: 20px; 
    height: 100%; 
    background-color: white; 
    width: 5px;
}
.btn {
    .counter-wrapper {
        position: absolute;
        margin-top: -40px;
        margin-left: 15px;
    }
}
.tuto-wrapper {
    position: absolute;
    right: 80px;
    z-index: 9999;
    max-width: 450px;
}
.arrow_box {
    position: relative;
    // background: theme-color('peach');
    // border: 4px solid theme-color('red');
}
.arrow_box:after, .arrow_box:before {
    left: 100%;
    top: 13%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.arrow_box:after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: theme-color('d-blue');
    border-width: 15px;
    margin-top: -15px;
}
.arrow_box:before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: theme-color('lightgray');
    border-width: 18px;
    margin-top: -18px;
}
.logs-container.top-0.left-0 {
    position: fixed !important;
    left: 0;
    top: 0;
    z-index: 9999;
}

.hyg-in-range {
    background: theme-color('lightgray');
}
.hyg-in-range::-webkit-slider-thumb {
    background: theme-color('peach');
}

.hyg-in-range::-moz-range-thumb {
    background: theme-color('peach');
}
.ico-field {
    margin-top: 5px;
    position: absolute;
    right: 20px;
    transition: all .3s;
    z-index: 990;
}
.ico-field:hover {
    cursor: pointer;
}
.show-cursor-pointer {
    cursor: pointer;
}
.legend-click.active {
    transform: scale(1.2);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.836);
}
.a-transition {
    transition: all .3s;
}
.table th, .table td {
    border-top: none !important;
}