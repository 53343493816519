.input-group {
    // height: 100%;
}
.input-group-text.min-width-50 {
    min-width: 50px !important;
}
.input-group-text.min-width-100 {
    min-width: 100px !important;
}
.input-group-text.min-width-150 {
    min-width: 150px !important;
}
.input-group-text.min-width-160 {
    min-width: 160px !important;
}
.input-group-text.min-width-180 {
    min-width: 180px !important;
}
.input-group-text.min-width-190 {
    min-width: 190px !important;
}
.input-group-text.min-width-200 {
    min-width: 200px !important;
}

.update-zone {
    height: 100%;
    background-color: rgb(71, 138, 224) !important;
    text-align: center;
    color: white;
}
.update-zone:hover {
    background-color: rgb(53, 99, 160) !important;
}
.delete-zone {
    height: 100%;
    background-color: rgb(224, 71, 71) !important;
    text-align: center;
    color: white;
}
.delete-zone:hover {
    background-color: rgb(160, 53, 53) !important;
}

// Tinymce 
.tox-statusbar {
    display: none !important;
}

// Vich image
.vich-image {
    display: inline-block;
}
.vich-image img {
    width: 100% !important;
}
.vich-image a img {
    width: 100% !important;
    max-width: 250px;
    margin: auto;
}
.vich-image .custom-file {
    display: none !important;
}
.vich-image-100 .vich-image a img {
    max-width: 100px !important;
}

div.input-group {
    .select2-selection.select2-selection--single {
        height: 100% !important;
    }
    .select2.select2-container .select2-selection {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border: 1px solid #ced4da;
    }
}
.min-height-500 {
    min-height: 500px !important;
}
.form-row.hide {
    display: none;
}
.form-transparent.form-img {
    display: none !important;
}

.form-badge {
    display: block !important;
    width: 100% !important;
}
.form-transparent {
    width: 100%;
    background-color: transparent;
    border-color: transparent;
    font-family: 'Oswald' !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-transparent.h1 {
    color: theme-color('red') !important;
    font-size: 40px;
}
.form-transparent.h2 {
    color: theme-color('red') !important;
    font-size: 32px;
}
.form-transparent.h3 {
    font-size: 20px;
    font-weight: 700;
}
.form-transparent.text-center {
    text-align: center !important;
}
.form-transparent:focus {
    box-shadow: none !important;
    display: block;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ft-badge-unsvg {
    transition: all .3s;
}
.ft-badge-unsvg:hover {
    cursor: pointer;
}

.form-transparent.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    width: 100%;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

// Input range
.hyg-in-range {
    -webkit-appearance: none;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}
.hyg-in-range:hover {
    opacity: 1;
    cursor: ew-resize;
}
.hyg-in-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 30px;
    cursor: pointer;
}
.hyg-in-range::-moz-range-thumb {
    width: 25px;
    height: 30px;
    cursor: pointer;
}